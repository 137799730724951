import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  InputRightElement,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { Col, FormGroup, Input, InputGroup, Row } from "reactstrap";
import { FcGoogle } from "react-icons/fc";
import { FaUser, FaUserPlus, FaLock, FaMoneyBill } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { IoIosCall } from "react-icons/io";
import { TiLock } from "react-icons/ti";
import { FiGlobe } from "react-icons/fi";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { makeApiRequest } from "middleware/api";
import Alert from "middleware/alert";
import SuccessfulLoginModal from "./modal";
import logo from "assets/img/logo-black.png";
import telCodes from "./telephone.json";
import countries from "./countries.json";

function SignUp() {
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");

  const [showPassword, setShowPassword] = useState(false);
  const [accountTypes, setAccountTypes] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [status, setStatus] = useState("error");
  const [message, setMessage] = useState("");

  useEffect(async () => {
    try {
      let response = await makeApiRequest("/account-type", "GET", null, {
        "Content-Type": "application/json",
      });

      if (response.status === 200) {
        const responseData = response.data.data;
        console.log(responseData);

        setAccountTypes(responseData);
      }
    } catch (error) {
      // console.log(error);
    }

    try {
      let response = await makeApiRequest("/currencies", "GET", null, {
        "Content-Type": "application/json",
      });

      if (response.status === 200) {
        const responseData = response.data.data;

        setCurrencies(responseData);
      }
    } catch (error) {
      // console.log(error);
    }
  }, []);

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();
  let ref = query.get("ref");

  const handlePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const handleSignUpSubmit = async (event) => {
    event.preventDefault();

    setDisableButton(true);

    const formData = new FormData(event.target);
    let signUpData = Object.fromEntries(formData);
    signUpData.privacy_status = 1;
    signUpData.ref_id = ref;
    signUpData.phone_number = signUpData.tel_code + signUpData.phone_number;
    delete signUpData.tel_code;

    try {
      let response = await makeApiRequest("/register", "POST", signUpData);

      setShowAlert(true);

      if (response.status === 200) {
        const responseData = response.data;
        setStatus("success");
        setMessage("Sign Up Successful! Check your email to verify");

        localStorage.setItem("token", responseData.data.token);
      } else {
        setStatus("error");
        if (response?.response?.status === 422) {
          let errors = response.response.data.errors;

          errors?.email && setMessage(errors?.email[0]);
          errors?.username && setMessage(errors?.username[0]);
          errors?.password && setMessage(errors?.password[0]);
          errors?.full_name && setMessage(errors?.full_name[0]);
          errors?.phone_number && setMessage(errors?.phone_number[0]);
          errors?.country && setMessage(errors?.country[0]);
          errors?.privacy_status && setMessage(errors?.privacy_status[0]);
          errors?.currency_id && setMessage(errors?.currency_id[0]);
        } else if (response?.response?.status === 401) {
          setMessage(response.response.data.message);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setDisableButton(false);
    }
  };

  useEffect(() => {
    // Hide the alert after 2 seconds
    const timer = setTimeout(() => {
      setShowAlert(false);
      if (status == "success") {
        window.location.href = "/auth/sign-in";
      }
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [showAlert]);

  return (
    <Flex
      maxW={{ base: "100%", md: "max-content" }}
      w="90vw"
      mx="auto"
      me="auto"
      h="100%"
      alignItems="start"
      justifyContent="center"
      mb={{ base: "30px", md: "60px" }}
      px={{ base: "25px", md: "0px", sm: "0px" }}
      mt={{ base: "40px", md: "14vh" }}
      flexDirection="column"
    >
      <div className="d-flex justify-content-center w-100 auth-logo-container">
        <a href="https://tophedgefortune.net">
          <img src={logo} className="img-fluid auth-logo" alt="logo" />
        </a>
      </div>
      <Flex
        zIndex="2"
        direction="column"
        w={{ base: "100%", md: "820px" }}
        maxW="100%"
        background="transparent"
        borderRadius="15px"
        mx={{ base: "0", lg: "unset" }}
        me="auto"
        mb={{ base: "20px", md: "auto" }}
      >
        <form className="auth-form" onSubmit={handleSignUpSubmit}>
          <h3 className="text-dark text-center mb-lg-4 mb-3">Sign Up</h3>
          <Row>
            <Col lg={6} md={6} sm={12}>
              <FormControl>
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="600"
                  color={textColor}
                  mb="8px"
                >
                  Full Name<Text color={brandStars}>*</Text>
                </FormLabel>

                <FormGroup>
                  <InputGroup>
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <FaUserPlus height="100%" />
                      </span>
                    </div>

                    <Input
                      required={true}
                      variant="auth"
                      fontSize="sm"
                      ms={{ base: "0px", md: "0px" }}
                      type="text"
                      name="full_name"
                      placeholder="Enter Full Name"
                      mb="20px"
                      fontWeight="600"
                      size="lg"
                    />
                  </InputGroup>
                </FormGroup>
              </FormControl>
            </Col>
            <Col lg={6} md={6} sm={12}>
              <FormControl>
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="600"
                  color={textColor}
                  mb="8px"
                >
                  Email<Text color={brandStars}>*</Text>
                </FormLabel>

                <InputGroup>
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <MdEmail height="100%" />
                    </span>
                  </div>

                  <Input
                    required={true}
                    variant="auth"
                    fontSize="sm"
                    ms={{ base: "0px", md: "0px" }}
                    type="email"
                    name="email"
                    placeholder="Enter Email Address"
                    mb="24px"
                    fontWeight="600"
                    size="lg"
                  />
                </InputGroup>
              </FormControl>
            </Col>
            <Col lg={6} md={6} sm={12}>
              <FormControl>
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="600"
                  color={textColor}
                  mb="8px"
                >
                  Username<Text color={brandStars}>*</Text>
                </FormLabel>

                <InputGroup>
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <FaUser height="100%" />
                    </span>
                  </div>

                  <Input
                    required={true}
                    variant="auth"
                    fontSize="sm"
                    ms={{ base: "0px", md: "0px" }}
                    type="text"
                    name="username"
                    placeholder="Enter Username"
                    mb="24px"
                    fontWeight="600"
                    size="lg"
                  />
                </InputGroup>
              </FormControl>
            </Col>
            
            <Col lg={6} md={6} sm={12}>
              <FormControl>
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="600"
                  color={textColor}
                  mb="8px"
                >
                  Country<Text color={brandStars}>*</Text>
                </FormLabel>

                <InputGroup>
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <FiGlobe height="100%" />
                    </span>
                  </div>

                  <Input
                    required={true}
                    type="select"
                    className="form-control"
                    variant="auth"
                    fontSize="sm"
                    ms={{ base: "0px", md: "0px" }}
                    name="country"
                    placeholder="Your country"
                    mb="24px"
                    fontWeight="600"
                    size="lg"
                  >
                    <option value="" disabled={true}>
                      --Country--
                    </option>
                    {countries?.map((data, key) => {
                      return <option key={key} value={data.name}>{data.name}</option>;
                    })}
                  </Input>
                </InputGroup>
              </FormControl>
            </Col>
            <Col lg={12} md={12} sm={12}>
              <FormControl>
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="600"
                  color={textColor}
                  mb="8px"
                >
                  Phone Number<Text color={brandStars}>*</Text>
                </FormLabel>
                <FormGroup className="d-block d-lg-flex">
                  <InputGroup className="">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <FiGlobe height="100%" />
                      </span>
                    </div>
                    <Input
                      className=" form-control tel-cod"
                      required={true}
                      variant="auth"
                      fontSize="sm"
                      ms={{ base: "0px", md: "0px" }}
                      type="select"
                      name="tel_code"
                      placeholder="123-456-7890"
                      mb="24px"
                      fontWeight="600"
                      size="lg"

                      // style={{"borderRadius": "15px"}}
                    >
                      <option value="" selected={true} disabled={true}>
                        Telephone Code
                      </option>
                      {Object.entries(telCodes).map(([country, code], key) => (
                        <option key={key} value={code}>
                          {country} ({code})
                        </option>
                      ))}
                    </Input>
                  </InputGroup>

                  <InputGroup>
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <IoIosCall height="100%" />
                      </span>
                    </div>

                    <Input
                      required={true}
                      variant="auth"
                      fontSize="sm"
                      ms={{ base: "0px", md: "0px" }}
                      type="tel"
                      name="phone_number"
                      placeholder="Enter Phone Number"
                      mb="24px"
                      fontWeight="600"
                      size="lg"
                    />
                  </InputGroup>
                </FormGroup>
              </FormControl>
            </Col>
            <Col lg={6} md={6} sm={12}>
              <FormControl>
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="600"
                  color={textColor}
                  mb="8px"
                >
                  Account Type<Text color={brandStars}>*</Text>
                </FormLabel>

                <FormGroup>
                  <InputGroup size="md">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <FaMoneyBill height="100%" />
                      </span>
                    </div>
                    <Input
                      className="form-control"
                      required={true}
                      variant="auth"
                      fontSize="sm"
                      ms={{ base: "0px", md: "0px" }}
                      type="select"
                      name="account_type"
                      mb="24px"
                      fontWeight="600"
                      size="lg"
                    >
                      {accountTypes?.map((data, key) => {
                        return (
                          <option key={key} value={data}>
                            {data}
                          </option>
                        );
                      })}
                    </Input>
                  </InputGroup>
                </FormGroup>
              </FormControl>
            </Col>
            <Col lg={6} md={6} sm={12}>
              <FormControl>
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="600"
                  color={textColor}
                  mb="8px"
                >
                  Currency (cannot be changed)<Text color={brandStars}>*</Text>
                </FormLabel>

                <FormGroup>
                  <InputGroup size="md">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <FaMoneyBill height="100%" />
                      </span>
                    </div>
                    <Input
                      className="form-control"
                      required={true}
                      variant="auth"
                      fontSize="sm"
                      ms={{ base: "0px", md: "0px" }}
                      type="select"
                      name="currency_id"
                      mb="24px"
                      fontWeight="600"
                      size="lg"
                    >
                      {currencies?.map((data, key) => {
                        return (
                          <option key={key} value={data?.id}>
                            {data?.code} ({data?.symbol})
                          </option>
                        );
                      })}
                    </Input>
                  </InputGroup>
                </FormGroup>
              </FormControl>
            </Col>
           
            <Col lg={6} md={6} sm={12}>
              <FormControl>
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="600"
                  color={textColor}
                  mb="8px"
                >
                  Confirm Password<Text color={brandStars}>*</Text>
                </FormLabel>
                <InputGroup size="md">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <FaLock height="100%" />
                    </span>
                  </div>

                  <Input
                    required={true}
                    fontSize="sm"
                    placeholder="Confirm Password"
                    mb="24px"
                    size="lg"
                    type={showPassword ? "text" : "password"}
                    name="password_confirmation"
                    variant="auth"
                  />
                  <InputRightElement
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    mt="1rem"
                    me="0.8rem"
                  >
                    <Icon
                      color={textColorSecondary}
                      _hover={{ cursor: "pointer" }}
                      as={showPassword ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                      onClick={handlePasswordVisibility}
                    />
                  </InputRightElement>
                </InputGroup>
              </FormControl>
            </Col>
            <Col lg={6} md={6} sm={12}>
              <FormControl>
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="600"
                  color={textColor}
                  mb="8px"
                >
                  Password<Text color={brandStars}>*</Text>
                </FormLabel>
                <InputGroup size="md">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <FaLock height="100%" />
                    </span>
                  </div>
                  <Input
                    required={true}
                    fontSize="sm"
                    placeholder="Enter Password"
                    mb="24px"
                    size="lg"
                    type={showPassword ? "text" : "password"}
                    name="password"
                    variant="auth"
                  />
                  <InputRightElement
                    display="flex"
                    alignItems="center"
                    mt="1rem"
                    me="0.8rem"
                  >
                    <Icon
                      color={textColorSecondary}
                      _hover={{ cursor: "pointer" }}
                      as={showPassword ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                      onClick={handlePasswordVisibility}
                    />
                  </InputRightElement>
                </InputGroup>
              </FormControl>
            </Col>
            
            
          </Row>

          <FormControl>
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="600"
              color={textColor}
              mb="8px"
            ></FormLabel>
            <Checkbox
              className="privacy"
              id="privacy_status"
              colorScheme="brandScheme"
              name="privacy_status"
              mb="24px"
              size="lg"
              fontWeight="600"
              required={true}
            >
              I accept the Terms & Conditions of Top Hedge Fortune Ltd. (FCA
              Regulated) and
              <a
                className="text-primary"
                href="https://tophedgefortune.net/risk-disclosure"
              >
                {" "}
                Risk Disclosure
              </a>{" "}
            </Checkbox>
          </FormControl>
          <FormControl>
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="600"
              color={textColor}
              mb="8px"
            ></FormLabel>
            <Checkbox
              className="privacy"
              id="privacy_status"
              colorScheme="brandScheme"
              name="privacy_status"
              mb="24px"
              size="lg"
              fontWeight="600"
              required={true}
            >
              I acknowledge that my information will be used in accordance with
              the and{" "}
              <a
                className="text-primary"
                href="https://tophedgefortune.net/policy"
              >
                {" "}
                Privacy Policy
              </a>{" "}
              and{" "}
              <a
                className="text-primary"
                href="https://tophedgefortune.net/cookie-policy"
              >
                {" "}
                Cookie Policy
              </a>{" "}
              of Top Hedge Fortune Ltd.
            </Checkbox>
          </FormControl>

          <Button
            disabled={disableButton}
            type="submit"
            fontSize="sm"
            variant="brand"
            fontWeight="600"
            w="100%"
            h="50px"
            mb="24px"
          >
            Sign Up
          </Button>

          {showAlert && (
            <Alert className="w-100 p-4" message={message} status={status} />
          )}

          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="start"
            maxW="100%"
            mt="0px"
          >
            <Text color={textColorDetails} fontWeight="400" fontSize="14px">
              Already registered?
              <NavLink to="/auth/sign-in">
                <Text
                  color={textColorBrand}
                  as="span"
                  ms="5px"
                  fontWeight="600"
                >
                  Sign In
                </Text>
              </NavLink>
            </Text>
          </Flex>
        </form>
      </Flex>
    </Flex>
  );
}

export default SignUp;
