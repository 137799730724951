import { Box, SimpleGrid } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";
import Card from "components/card/Card.js";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import dummy from "./dummy.json";
import { makeApiRequest } from "middleware/api";

export default function Transactions() {
  const [transactions, setTransactions] = useState([]);
  const [withdrawals, setWithdrawals] = useState(transactions?.withdrawals);
  const [deposit, setDeposit] = useState(transactions?.deposit);
  const [profile, setProfile] = useState({});
  const [copyTrades, setCopyTrades] = useState([]);
  const [currency, setCurrency] = useState("");
  const [others, setOthers] = useState(transactions?.others);

  let token = localStorage.getItem("token");

  useEffect(async () => {
    try {
      let response = await makeApiRequest("/transactions", "GET", null, {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      });

      if (response.status === 200) {
        const responseData = response.data.data;

        setTransactions(responseData);
        setDeposit(responseData?.deposit)
        setWithdrawals(responseData?.withdrawal)
        setOthers(responseData?.other)
      }
    } catch (error) {}
  }, []);

  useEffect(async () => {
    // console.log(token);
    try {
      let response = await makeApiRequest("/profile", "GET", null, {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      });

      if (response.status === 200) {
        const responseData = response.data.data;
        setCurrency(responseData.currency.symbol)
        setProfile(responseData);
      }
    } catch (error) {
      // console.log(error);
    }
  }, []);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1, md: 1, lg: 1 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        <Card mb={{ base: "0px", "2xl": "20px" }}>
          <Tabs
            defaultActiveKey="deposit"
            id="justify-tab-example"
            className="mb-3 d-flex"
          >
            <Tab eventKey="deposit" title="Deposit">
              <h2 className="h2 text-center">Deposit Transactions</h2>
              <div className="table-responsive">
                <Table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Amount</th>
                      <th>Payment Method</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {deposit?.map((item, key) => {
                      let statusBadge = "";

                      switch (item.status) {
                        case "completed":
                          statusBadge = "badge text-bg-success";
                          break;
                        case "pending":
                          statusBadge = "badge text-bg-warning";
                          break;
                        case "rejected":
                          statusBadge = "badge text-bg-danger";
                          break;
                        default:
                          statusBadge = "badge text-bg-info"; // Default color if the status is not recognized
                      }

                      return (
                        <tr key={key}>
                          <td>{item.date}</td>
                          <td>{currency}{item.amount}</td>
                          <td>{item.payment_mode}</td>
                          <td>
                            <div className={statusBadge}>{item.status}</div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </Tab>
            <Tab eventKey="withdrawals" title="Withdrawals">
              <h2 className="h2 text-center">Withdrawal Transactions</h2>
              <div className="table-responsive">
                <Table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Amount</th>
                      <th>Amount + Charges</th>
                      <th>Receiving Method</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {withdrawals?.map((item, key) => {
                      let statusBadge = "";

                      switch (item.status) {
                        case "completed":
                          statusBadge = "badge text-bg-success";
                          break;
                        case "pending":
                          statusBadge = "badge text-bg-warning";
                          break;
                        case "rejected":
                          statusBadge = "badge text-bg-danger";
                          break;
                        default:
                          statusBadge = "badge text-bg-info"; // Default color if the status is not recognized
                      }

                      return (
                        <tr key={key}>
                          <td>{item.date}</td>
                          <td>{currency}{item.amount}</td>
                          <td>{currency}{item.total_amount}</td>
                          <td>{item.receiving_mode}</td>
                          <td>
                            <div className={statusBadge}>{item.status}</div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </Tab>
            <Tab eventKey="others" title="Others">
              <h2 className="h2 text-center">Other Transactions</h2>
              <div className="table-responsive">
                <Table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Amount</th>
                      <th>Type</th>
                      <th>Plan/Narration</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {others?.map((item, key) => {
                      let statusBadge = "";

                      switch (item.status) {
                        case "completed":
                          statusBadge = "badge text-bg-success";
                          break;
                        case "pending":
                          statusBadge = "badge text-bg-warning";
                          break;
                        case "rejected":
                          statusBadge = "badge text-bg-danger";
                          break;
                        default:
                          statusBadge = "badge text-bg-info"; // Default color if the status is not recognized
                      }

                      return (
                        <tr key={key}>
                          <td>{item.date}</td>
                          <td>{currency}{item.amount}</td>
                          <td>{item.type}</td>
                          <td>{item.naration}</td>
                          <td>
                            <div className={statusBadge}>{item.status}</div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </Tab>
          </Tabs>
        </Card>
      </SimpleGrid>
    </Box>
  );
}
