import { Box, SimpleGrid } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Container,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import Card from "components/card/Card.js";
import Alert from "middleware/alert";
import { makeApiRequest } from "middleware/api";

export default function Support() {
  const [profile, setProfile] = useState({});

  const [showAlert, setShowAlert] = useState(false);
  const [status, setStatus] = useState("error");
  const [disableButton, setDisableButton] = useState(false);
  const [message, setMessage] = useState("");
  const [text, setText] = useState("");

  let token = localStorage.getItem("token");

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowAlert(false);
    }, 4000);

    return () => {
      clearTimeout(timer);
    };
  }, [showAlert]);

  useEffect(async () => {
    try {
      let response = await makeApiRequest("/profile", "GET", null, {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      });

      if (response.status === 200) {
        const responseData = response.data.data;

        setProfile(responseData);
        setText("")
      }
    } catch (error) {
      // console.log(error);
    }
  }, []);

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    let data = {
      message: text,
    };

    // console.log(data)
    try {
      let response = await makeApiRequest("/send-message", "POST", data, {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      });

      setShowAlert(true);

      // console.log(response);

      if (response.status === 200) {
        setStatus("success");
        setMessage("Message sent successfully");
      } else {
        setStatus("error");

        if (response.response.status === 422) {
          response.response.data.errors.message &&
            setMessage(response.response.data.errors.message[0]);
        } else if (response.response.status === 401) {
          setMessage(response.response.data.message);
        } else if (response.response.status === 400) {
          setMessage(response.response.data.message);
        }
      }
    } catch (error) {
      // console.log(error);
      setStatus("error");
      setMessage("An Error Ocurred");
    }
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1, md: 2, lg: 1 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        <Card className="min-vh-100" mb={{ base: "0px", "2xl": "20px" }}>
          <h2 className="h2 text-center">Top Hedge Fortune Support</h2>
          <div className="h6 d-flex justify-content-center">
            For inquiries, suggestions or complains. Mail us
          </div>
          <div className="body py-3">
            <Row className="mx-auto justify-content-center">
              <Col md={8}>
                <div className="d-flex justify-content-center text-warning">
                  {profile.support_email}
                </div>
                <Form onSubmit={handleFormSubmit}>
                  <FormGroup>
                    <Label for="message">Message</Label>
                    <textarea
                      id="message"
                      value={text}
                      className="form-control w-100 p-5"
                      onChange={(e) => setText(e.target.value)}
                      placeholder="Enter your message to support"
                      required
                    />
                  </FormGroup>
                  <Button className="w-100" color="primary" type="submit">
                    Send
                  </Button>
                </Form>
              </Col>
            </Row>
          </div>
        </Card>
        {showAlert && (
          <Alert className="w-100 p-4" message={message} status={status} />
        )}
      </SimpleGrid>
    </Box>
  );
}
