import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useParams, NavLink, useLocation } from "react-router-dom";
import { makeApiRequest } from "middleware/api";
import logo from "assets/img/logo-black.png";
import Alert from "middleware/alert";

function EmailVerification() {
  const [verificationStatus, setVerificationStatus] = useState(0);
  const [resendStatus, setResendStatus] = useState(null);
  const successColor = useColorModeValue("green.600", "green.300");
  const errorColor = useColorModeValue("red.600", "red.300");
  const loadingColor = useColorModeValue("gray.600", "gray.300");
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorBrand = useColorModeValue("brand.500", "white");

  const [showAlert, setShowAlert] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [status, setStatus] = useState("error");
  const [message, setMessage] = useState("");

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();
  let token = query.get("token");

  useEffect(async () => {

    let data = {
      token,
    };

    try {
      let response = await makeApiRequest("/verification", "POST", data);

      if (response?.status === 200) {
        setVerificationStatus(200);
      } else {
        if (response?.response?.status === 422) {
          setVerificationStatus(422);
        } else if (response?.response?.status === 400) {
          setVerificationStatus(400);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleResend = async () => {
    try {
      let response = await makeApiRequest(
        "/resend-verification-link",
        "POST",
        null,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      );
      console.log(response);
      if (response?.status === 200) {
        setResendStatus(true);
      }
      else {
        setResendStatus(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getMessageColor = () => {
    switch (verificationStatus) {
      case "success":
        return successColor;
      case "error":
        return errorColor;
      default:
        return loadingColor;
    }
  };

  const getMessageText = () => {
    switch (verificationStatus) {
      case 200:
        return "Email successfully verified!";
      case 400:
        return "Invalid Verification Link";
      case 422:
        return "There was an error verifying your email. Please try again later.";
      default:
        return "Verifying email...";
    }
  };

  const getResendMessage = () => {
    switch (resendStatus) {
      case "success":
        return "Email resent successfully!";
      case "error":
        return "Failed to resend email. Please try again later.";
      default:
        return "";
    }
  };

  return (
    <Flex
      maxW={{ base: "100%", md: "max-content" }}
      w="100%"
      mx={{ base: "auto", lg: "0px" }}
      me="auto"
      h="100%"
      alignItems="start"
      justifyContent="center"
      mb={{ base: "30px", md: "60px" }}
      px={{ base: "25px", md: "0px" }}
      mt={{ base: "40px", md: "14vh" }}
      flexDirection="column"
    >
      {showAlert && (
        <Alert className="w-100 p-4" message={message} status={status} />
      )}
      <div className="d-flex justify-content-center w-100 px-5">
        <a href="https://tophedgefortune.net">
          <img src={logo} className="img-fluid auth-logo" alt="logo" />
        </a>
      </div>
      <Box me="auto">
        <Heading className="" color={textColor} fontSize="30px" mb="10px">
          {getMessageText()}
        </Heading>

        <Text
          mb="36px"
          ms="4px"
          color={textColorSecondary}
          fontWeight="400"
          fontSize="md"
        >
          {getResendMessage()}
        </Text>

        {verificationStatus === 400 && (
          <Button
            variant="brand"
            fontSize="sm"
            fontWeight="500"
            onClick={handleResend}
          >
            Resend Verification Email
          </Button>
        )}
        {verificationStatus === 200 && (
          <NavLink to="/auth/sign-in">
            <Button variant="brand" fontSize="sm" fontWeight="500" mt="20px">
              Back to Login
            </Button>
          </NavLink>
        )}
      </Box>
    </Flex>
  );
}

export default EmailVerification;
