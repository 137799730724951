import {
  Avatar,
  Box,
  Flex,
  FormLabel,
  Text,
  Icon,
  Select,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import Usa from "assets/img/dashboards/usa.png";
// Custom components
import MiniCalendar from "components/calendar/MiniCalendar";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import React, { useState, useEffect, useContext } from "react";
import {
  MdAccountBalanceWallet,
  MdAttachMoney,
  MdMonetizationOn,
  MdPeople,
  MdTrendingUp,
  MdCreditCard,
  MdMoneyOff,
  MdBusiness,
} from "react-icons/md";
import {
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Row,
  Col,
} from "reactstrap";

import PieCard from "views/admin/default/components/PieCard";
import TradingViewWidget from "react-tradingview-widget";
import { makeApiRequest } from "middleware/api";
import GlobalContext from "../../../layouts/admin/index";
import Alert from "middleware/alert";


export default function UserReports() {
  const [profile, setProfile] = useState({});
  const [copyTrades, setCopyTrades] = useState([]);
  const [currency, setCurrency] = useState("");
  // const { globalVariable, setGlobalVariable } = useContext(GlobalContext);
  let token = localStorage.getItem("token");
  const [isModalOpen, setModalOpen] = useState(false);
  const [copyTradeItem, setCopyTradeItem] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [status, setStatus] = useState("error");
  const [message, setMessage] = useState("");

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowAlert(false);
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, [showAlert]);

  useEffect(async () => {
    // console.log(token);
    try {
      let response = await makeApiRequest("/profile", "GET", null, {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      });

      if (response.status === 200) {
        const responseData = response.data.data;
        setCurrency(responseData.currency.symbol)
        setProfile(responseData);
      }
    } catch (error) {
      // console.log(error);
    }
  }, []);

  useEffect(async () => {
    // console.log(token);
    try {
      let response = await makeApiRequest("/copy-trade-list", "GET", null, {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      });

      if (response.status === 200) {
        const responseData = response.data.data;
        setCopyTrades(responseData);
      }
    } catch (error) {
      // console.log(error);
    }
  }, []);

  // Chakra Color Mode
  const brandColor = useColorModeValue("#283552", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "#fff");

  const toggleModal = (event, item) => {
    setCopyTradeItem(item);
    setModalOpen(!isModalOpen);
  };


  const handleFormSubmit = async (event, copyTradeItem) => {
    event.preventDefault();

    let data = {
      plan_id: copyTradeItem.plan_id,
      amount: Number(copyTradeItem?.amount.replace(/,/g, '')),
      signal_id: copyTradeItem.signal_id,
      copy_trade_id: copyTradeItem.id
    };


    try {
      let response = await makeApiRequest("/create-investment", "POST", data, {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      });

      setShowAlert(true);

      if (response.status === 200) {
        setStatus("success");
        setMessage("Investment initiated successful");
        setModalOpen(!isModalOpen);
      } else {
        setStatus("error");

        if (response.response.status === 422) {
          response.response.data.errors.plan_id &&
            setMessage(response.response.data.errors.plan_id[0]);
          response.response.data.errors.amount &&
            setMessage(response.response.data.errors.amount[0]);
        } else if (response.response.status === 401) {
          setMessage(response.response.data.message);
        } else if (response.response.status === 400) {
          setMessage(response.response.data.message);
        }
        setModalOpen(!isModalOpen);
      }
    } catch (error) {
      // console.log(error);
      setModalOpen(!isModalOpen);
    }
  };

  return (
    <>
      <Box
        pt={{ base: "130px", md: "80px", xl: "80px" }}
        px={{ base: "40px", md: "80px", xl: "80px" }}
      >
        <SimpleGrid
          columns={{ base: 1, md: 2, lg: 2, "2xl": 2 }}
          gap="5px"
          mb="20px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <div className="div">
            <h6 className="text-warning">
              Welcome,{" "}
              {`${
                profile?.full_name == undefined ? "---" : profile?.full_name
              }`}
            </h6>
          </div>
        </SimpleGrid>
      </Box>
      <Box>
        <SimpleGrid
          columns={{ base: 2, md: 2, lg: 4, "2xl": 6 }}
          gap="20px"
          mb="20px"
          mt="10px"
        >
          <MiniStatistics
            startContent={
              <IconBox
                w="30px"
                h="30px"
                bg={boxBg}
                icon={
                  <Icon
                    w="22px"
                    h="22px"
                    as={MdAccountBalanceWallet}
                    color={brandColor}
                  />
                }
              />
            }
            name="Account Balance"
            value={  `${currency}${
              profile?.wallet_balance == undefined
                ? "---"
                : profile?.wallet_balance
            }`}
          />
          <MiniStatistics
            startContent={
              <IconBox
                w="30px"
                h="30px"
                bg={boxBg}
                icon={
                  <Icon
                    w="22px"
                    h="22px"
                    as={MdAccountBalanceWallet}
                    color={brandColor}
                  />
                }
              />
            }
            name="Invested"
            value={`${currency}${
              profile?.total_investment_amount == undefined
                ? "---"
                : profile?.total_investment_amount
            }`}
          />
          <MiniStatistics
            startContent={
              <IconBox
                w="30px"
                h="30px"
                bg={boxBg}
                icon={
                  <Icon
                    w="22px"
                    h="22px"
                    as={MdAttachMoney}
                    color={brandColor}
                  />
                }
              />
            }
            name="Total Profits"
            value={`${currency}${
              profile?.total_earning == undefined
                ? "---"
                : profile?.total_earning
            }`}
          />
          <MiniStatistics
            startContent={
              <IconBox
                w="30px"
                h="30px"
                bg={boxBg}
                icon={
                  <Icon
                    w="22px"
                    h="22px"
                    as={MdMonetizationOn}
                    color={brandColor}
                  />
                }
              />
            }
            name="Total Bonus"
            value={`${currency}${
              profile?.total_bonus == undefined ? "---" : profile?.total_bonus
            }`}
          />
          <MiniStatistics
            startContent={
              <IconBox
                w="30px"
                h="30px"
                bg={boxBg}
                icon={
                  <Icon w="22px" h="22px" as={MdPeople} color={brandColor} />
                }
              />
            }
            name="Total Referral Bonus"
            value={`${currency}${
              profile?.referral_bonus == undefined
                ? "---"
                : profile?.referral_bonus
            }`}
          />
          <MiniStatistics
            startContent={
              <IconBox
                w="30px"
                h="30px"
                bg={boxBg}
                icon={
                  <Icon
                    w="22px"
                    h="22px"
                    as={MdTrendingUp}
                    color={brandColor}
                  />
                }
              />
            }
            name="Total Investment Plans"
            value={`${
              profile?.total_investment_plan == undefined
                ? "---"
                : profile?.total_investment_plan
            }`}
          />
          <MiniStatistics
            startContent={
              <IconBox
                w="30px"
                h="30px"
                bg={boxBg}
                icon={
                  <Icon w="22px" h="22px" as={MdBusiness} color={brandColor} />
                }
              />
            }
            name="Active Investment Plans"
            value={`${
              profile?.total_active_investment_plan == undefined
                ? "---"
                : profile?.total_active_investment_plan
            }`}
          />
          <MiniStatistics
            startContent={
              <IconBox
                w="30px"
                h="30px"
                bg={boxBg}
                icon={
                  <Icon
                    w="22px"
                    h="22px"
                    as={MdCreditCard}
                    color={brandColor}
                  />
                }
              />
            }
            name="Total Deposits"
            value={`${currency}${
              profile?.total_deposit == undefined
                ? "---"
                : profile?.total_deposit
            }`}
          />
        </SimpleGrid>

        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" mb="20px">
          <MiniStatistics
            startContent={
              <IconBox
                w="30px"
                h="30px"
                bg={boxBg}
                icon={
                  <Icon w="22px" h="22px" as={MdMoneyOff} color={brandColor} />
                }
              />
            }
            name="Account Type"
            value={`${
              profile?.account_type == undefined
                ? "---"
                : profile?.account_type.toUpperCase()
            }`}
          />
          <MiniStatistics
            startContent={
              <IconBox
                w="30px"
                h="30px"
                bg={boxBg}
                icon={
                  <Icon
                    w="22px"
                    h="22px"
                    as={MdCreditCard}
                    color={brandColor}
                  />
                }
              />
            }
            name="Trade Type"
            value={`${
              profile?.current_investment?.signal == undefined
                ? "---"
                : profile?.current_investment?.signal
            }`}
          />
          <MiniStatistics
            startContent={
              <IconBox
                w="30px"
                h="30px"
                bg={boxBg}
                icon={
                  <Icon w="22px" h="22px" as={MdMoneyOff} color={brandColor} />
                }
              />
            }
            name="Total Withdrawals"
            value={`${currency}${
              profile?.total_withdrawal == undefined
                ? "---"
                : profile?.total_withdrawal
            }`}
          />
          <MiniStatistics
            startContent={
              <IconBox
                w="30px"
                h="30px"
                bg={boxBg}
                icon={
                  <Icon
                    w="22px"
                    h="22px"
                    as={MdCreditCard}
                    color={brandColor}
                  />
                }
              />
            }
            name="Account Status"
            value={`${
              profile?.kyc_status == undefined
                ? "---"
                : profile?.kyc_status == 0
                ? "Unverified"
                : profile?.kyc_status == 1
                ? "Pending Verification"
                : profile?.kyc_status == 2
                ? "Verified"
                : "---"
            }`}
          />
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px" mb="20px">
          <div>
            <h2 className="h2 text-center">Copy Trades</h2>
            <div className="table-responsive">
              <Table className="table table-bordered table-striped bg-dark">
                <thead>
                  <tr>
                    <th>Trader</th>
                    <th>Plan</th>
                    <th>Signal</th>
                    <th>Amount</th>
                    <th>ROI</th>
                    <th>Duration</th>
                    <th>Trade</th>
                  </tr>
                </thead>
                <tbody>
                  {copyTrades?.map((item, key) => {
                    return (
                      <tr key={key}>
                        <td>
                          {
                            (item.investor_name =
                              item.investor_name.charAt(0).toUpperCase() +
                              item.investor_name.slice(1))
                          }
                        </td>
                        <td>{item.plan_name}</td>
                        <td>{item.signal_name}</td>
                        <td>{currency}{item.amount}</td>
                        <td className="text-warning">
                        {currency}{item.expected_return}
                        </td>
                        <td>{item.duration_label}</td>

                        <td>
                          <Button
                            className="w-100"
                            color="success"
                            type="button"
                            onClick={(event) => toggleModal(event, item)}
                          >
                            Trade
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" mb="20px">
          <PieCard
            deposit={profile?.total_deposit}
            profits={profile?.total_earning}
            currency={currency}
          />
          <MiniCalendar h="100%" minW="100%" selectRange={false} />
        </SimpleGrid>

        
      </Box>

      <Box className="card p-3 overflow-scroll">
        <h2 className="fw-bold mb-2">Personal Trading Chart</h2>
        {/* <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }}> */}
        {profile?.account_type == "Crypto Options" && (
          <TradingViewWidget symbol="BINANCE:BTCUSD" />
        )}
        {profile?.account_type == "Futures Options" && (
          <TradingViewWidget symbol="BINANCE:BTCUSDZ2023" />
        )}
        {profile?.account_type == "Forex Options" && (
          <TradingViewWidget symbol="FX:EURUSD" />
        )}
        {profile?.account_type == "Stock Options" && (
          <TradingViewWidget symbol="NASDAQ:TSLA" />
        )}
        {/* <TradingViewWidget symbol="BINANCE:BTCUSD" /> */}
        {/*  */}
        {/* </SimpleGrid> */}
      </Box>

      {showAlert && (
        <Alert className="w-100 p-4" message={message} status={status} />
      )}

      <Modal isOpen={isModalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Copy Trade Details</ModalHeader>
        <ModalBody>
          <Container>
            <Row className="py-1">
              <Col md={12}>
                <div className="">
                  Would you like to copy this trade?
                  <br />
                  Plan: <span className="fw-bold"> {copyTradeItem?.plan_name}</span>
                  <br />
                  Signal: <span className="fw-bold">{copyTradeItem?.signal_name}</span>
                  <br />
                  Duration: <span className="fw-bold">{copyTradeItem?.duration_label}</span>
                  <br />
                  Amount: <span className="fw-bold">{currency}{copyTradeItem?.amount}</span>
                  <br />
                  Expected Return: <span className="fw-bold">{currency}{copyTradeItem?.expected_return}</span>
                  <br />
                  Investor: <span className="fw-bold">{copyTradeItem?.investor_name}</span>
                </div>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>
            Cancel
          </Button>
          <Button color="success" onClick={ (event)=>handleFormSubmit(event, copyTradeItem)}>
            Trade
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
