import React, { useState, useEffect } from "react";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import logo from "assets/img/logo-black.png";
import Alert from "middleware/alert";
import { makeApiRequest } from "middleware/api";

function Unverified() {
  const history = useHistory();

  let token = localStorage.getItem("token");

  console.log(token)

  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorBrand = useColorModeValue("brand.500", "white");

  const [showAlert, setShowAlert] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [status, setStatus] = useState("error");
  const [message, setMessage] = useState("");

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowAlert(false);
    }, 4000);

    return () => {
      clearTimeout(timer);
    };
  }, [showAlert]);

  useEffect(() => {
    // if (token === null) {
    //   history.push("/auth/sign-in");
    // }
  }, []);

  const handleResend = async (event) => {
    event.preventDefault();
    setDisableButton(true);

    try {
      let response = await makeApiRequest(
        "/resend-verification-link",
        "POST",
        null,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      );

      console.log(response);

      setShowAlert(true);

      if (response?.status === 200) {
        setStatus("success");
        setMessage("Verification link has been sent to your email!");
      } else {
        setMessage("An Error Ocurred! Try again...");
        setDisableButton(false);
      }
    } catch (error) {
      console.log(error);
    }

  }

  return (
    <Flex
      maxW={{ base: "100%", md: "max-content" }}
      w="90vw"
      mx="auto"
      me="auto"
      h="100%"
      alignItems="start"
      justifyContent="center"
      mb={{ base: "30px", md: "60px" }}
      px={{ base: "25px", md: "0px", sm: "0px" }}
      mt={{ base: "40px", md: "14vh" }}
      flexDirection="column"
    >
      <div className="d-flex justify-content-center w-100 auth-logo-container">
        <a href="https://tophedgefortune.net">
          <img src={logo} className="img-fluid auth-logo" alt="logo" />
        </a>
      </div>
      <Box me="auto" className="pt-4">
        <Heading className="text-danger" fontSize="36px" mb="10px">
          Account not Verified
        </Heading>
        <Text
          mb="36px"
          ms="4px"
          color={textColorSecondary}
          fontWeight="400"
          fontSize="md"
        >
          Check your email to verify your account.
          <p>
            Click on the resend button if you didn't receive any verification
            mail
          </p>
        </Text>
      </Box>
      <Flex
        zIndex="2"
        direction="column"
        w={{ base: "100%", md: "420px" }}
        maxW="100%"
        background="transparent"
        borderRadius="15px"
        mx={{ base: "auto", lg: "unset" }}
        me="auto"
        mb={{ base: "20px", md: "auto" }}
      >
        <Button
          type="submit"
          fontSize="sm"
          variant="brand"
          fontWeight="500"
          w="100%"
          h="50px"
          mb="24px"
          disabled={disableButton}
          onClick={handleResend}
        >
           {status === "success" ? "Check your mail to verify" : "Resend Verification code"}
        </Button>
        {showAlert && (
          <Alert className="w-100 p-4" message={message} status={status} />
        )}
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="start"
          maxW="100%"
          mt="0px"
        >
          <NavLink to="/auth/sign-in">
            <Text color={textColorBrand} fontWeight="500" fontSize="14px">
              Back to Sign In
            </Text>
          </NavLink>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default Unverified;
