import React, { useEffect, useState } from "react";
import { useHistory, useLocation, NavLink } from "react-router-dom";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  InputRightElement,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { FormGroup, Input, InputGroup } from "reactstrap";
import { FaUser, FaUserPlus, FaLock, FaMoneyBill } from "react-icons/fa";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { makeApiRequest } from "middleware/api";
import Alert from "middleware/alert";
import logo from "assets/img/logo.png";

function ResetPassword() {
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");

  const [tokenValid, setTokenValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [resetSuccess, setResetSuccess] = useState(false);

  const [showAlert, setShowAlert] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [status, setStatus] = useState("error");
  const [message, setMessage] = useState("");

  const history = useHistory();

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();
  let reset_token = query.get("token");

  // Simulate token validation
  useEffect(async () => {
    let data = {
      reset_token,
    };

    try {
      let response = await makeApiRequest(
        "/verify-reset-password-link",
        "POST",
        data
      );

      if (response?.status === 200) {
        setTokenValid(true);
      } else {
        if (response?.response?.status === 422) {
          setTokenValid(false);
        } else if (response?.response?.status === 400) {
          setTokenValid(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleBackToLogin = () => {
    window.location.href = "/auth/login";
  };

  const handleResetPassword = async (event) => {
    event.preventDefault();
    setDisableButton(true);

    const formData = new FormData(event.target);
    const data = Object.fromEntries(formData);
    data.reset_token = reset_token;

    try {
      let response = await makeApiRequest("/reset-password", "POST", data);

      setShowAlert(true);

      console.log(response);

      if (response?.status === 200) {
        const responseData = response.data;
        setStatus("success");
        setMessage(responseData.message);
        resetSuccess(true);
      } else {
        setStatus("error");

        if (response?.response?.status === 422) {
          let errors = response.response.data.errors;
          console.log(errors.password);
          errors?.password && setMessage(errors?.password[0]);
          errors?.reset_token && setMessage(errors?.reset_token[0]);
        } else if (response?.response?.status === 400) {
          setMessage(response.response.data.message);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setDisableButton(false);
    }
  };

  useEffect(() => {
    // Hide the alert after 2 seconds
    const timer = setTimeout(() => {
      setShowAlert(false);
      if (status == "success") {
        window.location.href = "/auth/sign-in";
      }
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, [showAlert]);

  return (
    <Flex
      maxW={{ base: "100%", md: "max-content" }}
      w="90vw"
      mx={{ base: "auto", lg: "0px" }}
      me="auto"
      h="100%"
      alignItems="start"
      justifyContent="center"
      mb={{ base: "30px", md: "60px" }}
      px={{ base: "25px", md: "0px" }}
      mt={{ base: "40px", md: "14vh" }}
      flexDirection="column"
    >
      {showAlert && (
        <Alert className="w-100 p-4" message={message} status={status} />
      )}
      <div className="d-flex justify-content-center w-100">
        <a href="https://tophedgefortune.net">
          <img src={logo} className="img-fluid auth-logo" alt="logo" />
        </a>
      </div>

      {tokenValid ? (
        <>
          {!resetSuccess ? (
            <Box me="auto">
              <Flex
                zIndex="2"
                direction="column"
                w={{ base: "100%", md: "420px" }}
                maxW="100%"
                background="transparent"
                borderRadius="15px"
                mx={{ base: "auto", lg: "unset" }}
                me="auto"
                mb={{ base: "20px", md: "auto" }}
              >
                <form className="auth-form" onSubmit={handleResetPassword}>
                  <h3 className="text-center text-dark mb-3">Reset Password</h3>

                  <FormControl mb="24px">
                    <FormLabel
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      display="flex"
                    >
                      New Password
                    </FormLabel>
                    <InputGroup size="md">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <FaLock height="100%" />
                        </span>
                      </div>
                      <Input
                        required={true}
                        fontSize="sm"
                        placeholder="Min. 8 characters"
                        mb="24px"
                        size="lg"
                        type={showPassword ? "text" : "password"}
                        name="password"
                        variant="auth"
                      />

                      <InputRightElement
                        display="flex"
                        alignItems="center"
                        mt="4px"
                      >
                        <Icon
                          color="gray.400"
                          _hover={{ cursor: "pointer" }}
                          as={
                            showPassword
                              ? RiEyeCloseLine
                              : MdOutlineRemoveRedEye
                          }
                          onClick={handleShowPassword}
                        />
                      </InputRightElement>
                    </InputGroup>
                  </FormControl>

                  <FormControl mb="24px">
                    <FormLabel
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      display="flex"
                    >
                      Confirm New Password
                    </FormLabel>
                    <InputGroup size="md">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <FaLock height="100%" />
                        </span>
                      </div>
                      <Input
                        required={true}
                        fontSize="sm"
                        placeholder="Confirm your password"
                        mb="24px"
                        size="lg"
                        type={showPassword ? "text" : "password"}
                        name="password_confirmation"
                        variant="auth"
                      />
                      <InputRightElement
                        display="flex"
                        alignItems="center"
                        mt="4px"
                      >
                        <Icon
                          color="gray.400"
                          _hover={{ cursor: "pointer" }}
                          as={
                            showPassword
                              ? RiEyeCloseLine
                              : MdOutlineRemoveRedEye
                          }
                          onClick={handleShowPassword}
                        />
                      </InputRightElement>
                    </InputGroup>
                  </FormControl>

                  <Button
                    fontSize="sm"
                    variant="brand"
                    fontWeight="500"
                    w="100%"
                    h="50"
                    mb="24px"
                    type="submit"
                    disabled={disableButton}
                  >
                    Reset Password
                  </Button>
                </form>
              </Flex>
              <Flex
                flexDirection="column"
                justifyContent="center"
                alignItems="start"
                maxW="100%"
                mb="100px"
              >
                <NavLink to="/auth/sign-in">
                  <Text color={textColorBrand} fontWeight="500" fontSize="14px">
                    Back to Sign In
                  </Text>
                </NavLink>
              </Flex>
            </Box>
          ) : (
            <Box me="auto">
              <Heading className="" fontSize="36px" mb="10px">
                Password Reset Successful
              </Heading>
              <Text
                mb="36px"
                ms="4px"
                color="gray.400"
                fontWeight="400"
                fontSize="md"
              >
                Your password has been successfully reset.
              </Text>
              <Button
                fontSize="sm"
                variant="brand"
                fontWeight="500"
                w="100%"
                h="50"
                mb="24px"
                onClick={handleBackToLogin}
              >
                Back to Login
              </Button>
            </Box>
          )}
        </>
      ) : (
        <Box me="auto">
          <Heading className="" fontSize="36px" mb="10px">
            Invalid Token
          </Heading>
          <Text
            mb="36px"
            ms="4px"
            color="gray.400"
            fontWeight="400"
            fontSize="md"
          >
            The token provided is invalid. Please check your reset password
            link.
          </Text>
          <Button
            fontSize="sm"
            variant="brand"
            fontWeight="500"
            w="100%"
            h="50"
            mb="24px"
            onClick={handleBackToLogin}
          >
            Back to Login
          </Button>
        </Box>
      )}
    </Flex>
  );
}

export default ResetPassword;
